import { Button } from "@mantine/core";
import { useState } from "react";

type WhiteBlackBlueButtonProps = {
	name: string
	stateCallback: (state: number) => void
}

export const WhiteBlackBlueButton = (props: WhiteBlackBlueButtonProps) => {
	const { name, stateCallback } = props
	let [currentVariant, setCurrentVariant] = useState(0);
	const colorVariants = ["grey", "white", "black", "blue"]

	const setNextVariant = () => {
		setCurrentVariant((currentVariant) => {
			const nextVariant = currentVariant < colorVariants.length - 1 ? currentVariant + 1 : 0
			stateCallback(nextVariant)
			return nextVariant
		})
	}

	const isOnWhite = () => {
		return currentVariant === 1;
	}

	console.log("current color variant is", colorVariants[currentVariant])
	return (
		<Button
			color={isOnWhite() ? "black" : colorVariants[currentVariant]}
			variant={isOnWhite() ? "outline" : "filled"}
			onClick={setNextVariant}>
			{name}
		</Button>
	)
}
