import { Button, Container, Flex, Loader, SimpleGrid } from "@mantine/core";
import { FC, useState } from "react";
import { TeamPickerResponse } from "../api/Descriptors";
import { TeamPickerAPI } from "../api/TeamPicker";
import { SelectableButton } from "../components/SelectableButton";
import { usePlayers } from "../hooks/usePlayers";

export const TeamPicker: FC<{}> = () => {
  const { useAllPlayers } = usePlayers();
  const { isLoading, error, data } = useAllPlayers({ status: "active" });
  const [selected, setSelected] = useState<number[]>([]);
  const [pickedTeams, setPickedTeams] = useState<TeamPickerResponse>(
    {} as TeamPickerResponse,
  );

  const assembleName = (namePart: { fname: string; lname: string }) => {
    return `${namePart.lname} ${namePart.fname}`;
  };

  const addToSelected = (id: number) => {
    setSelected((prevState) => {
      const index = prevState.indexOf(id);
      if (index > -1) {
        return prevState;
      }
      prevState.push(id);
      return prevState;
    });
  };

  const removeFromSelected = (id: number) => {
    setSelected((prevState) => {
      const index = prevState.indexOf(id);
      if (index <= -1) {
        return prevState;
      }
      prevState.splice(index, 1);
      return prevState;
    });
  };

  const sendToTeamPicker = async () => {
    let api = new TeamPickerAPI();
    let resp = await api.getNewTeams(selected);
    setPickedTeams(resp);
  };

  const getNamesAsConcatStrings = (playerIds: number[]) => {
    if (data === undefined) return "";
    let names = playerIds.map((playerId) => {
      let playerInfo = data.find((p) => p.id === playerId);
      if (playerInfo === undefined) return "";
      return assembleName({ fname: playerInfo.fname, lname: playerInfo.lname });
    });
    return names.join(", ");
  };

  const isThePlayerSelected = (id: number) => {
    for (let i = 0; i < selected.length; i++) {
      if (selected[i] === id) return true;
    }
    return false;
  };

  if (error) {
    return <h2>{`Sajnos nem sikerült a művelet: ${error}`}</h2>;
  }

  if (isLoading || !data) {
    return <Loader my={20} size="lg" />;
  }

  if (pickedTeams.teams !== undefined) {
    return (
      <Container>
        {pickedTeams.teams.map((pick, index) => (
          <div>
            {`${index + 1}. elosztás: `}
            <br />
            {`Fehér csapat (${pick.team_a.strength.toFixed(2)}): `}
            <p key={100 * (index + 1)}>
              {getNamesAsConcatStrings(pick.team_a.members)}
            </p>
            {`Fekete csapat (${pick.team_b.strength.toFixed(2)}): `}
            <p key={200 * (index + 1)}>
              {getNamesAsConcatStrings(pick.team_b.members)}
            </p>
            <hr />
          </div>
        ))}
        {data.map((k) => (
          <SelectableButton
            key={k.id}
            text={assembleName({ fname: k.fname, lname: k.lname })}
            style={{ margin: "0.25rem" }}
            whenSelected={() => addToSelected(k.id)}
            whenDeselected={() => removeFromSelected(k.id)}
            initialSelectState={isThePlayerSelected(k.id)}
          />
        ))}
        <Button
          variant="success"
          onClick={sendToTeamPicker}
          style={{ margin: "1rem" }}
        >
          Bevitel
        </Button>
      </Container>
    );
  }

  const calculateNumberOfCols = (min: number, max: number) => {
    let numberOfColumns = 4;
    let remainder = data.length % 4;
    for (let i = min; i < max; i++) {
      if (data.length % i < remainder) {
        numberOfColumns = i;
        remainder = data.length % i;
      }
    }
    return numberOfColumns;
  };

  const numberOfCols = calculateNumberOfCols(3, 6);

  return (
    <Container>
      <Flex direction={"column"}>
        <SimpleGrid cols={numberOfCols}>
          {data.map((k) => (
            <SelectableButton
              key={k.id}
              fz={"md"}
              text={assembleName({ fname: k.fname, lname: k.lname })}
              whenSelected={() => addToSelected(k.id)}
              whenDeselected={() => removeFromSelected(k.id)}
              initialSelectState={false}
            />
          ))}
        </SimpleGrid>
        <Button
          onClick={sendToTeamPicker}
          style={{ marginLeft: "auto", marginRight: "auto", marginTop: "1rem" }}
        >
          Bevitel
        </Button>
      </Flex>
    </Container>
  );
};
