import { Text } from "@mantine/core"

export const AntonText = (text: string, fontWeight?: number) => {
  return (
    <Text
      style={{
        fontFamily: "Anton",
        letterSpacing: "0.03rem",
        fontWeight
      }}
    >
      {text}
    </Text>
  );
};
