import {
  AppShell,
  Burger,
  Button,
  Flex,
  Group,
  Image,
  NavLink,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { WhoAmIResponse } from "../api/Descriptors";
import { logout } from "../api/Login";
import { UsersClient } from "../api/Users";
import { AntonText } from "../util/text";
import styles from "./AppShell.module.css";
import { NotificationRegistrationButton } from "./notification/NotificationRegistrationButton";
export const isLoggedIn = () => {
  return localStorage.getItem("token") ? true : false;
};

export const MainAppShell = () => {
  const [opened, { toggle }] = useDisclosure();
  const usersClient = new UsersClient();
  const [whoAmI, setWhoAmI] = useState<WhoAmIResponse | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      usersClient.getWhoAmI().then((response) => {
        setWhoAmI(response);
        console.log("whoAmI", response);
      });
    } else {
      setWhoAmI(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdmin = () => {
    return isLoggedIn() && whoAmI?.roles && whoAmI.roles.includes("admin");
  };

  const isClubManager = () => {
    return (
      isLoggedIn() && whoAmI?.roles && whoAmI.roles.includes("club_manager")
    );
  };

  const AuthButton = () => {
    if (isLoggedIn()) {
      return (
        <Button
          variant="filled"
          color="red"
          size="sm"
          style={{ width: 300, marginBottom: "1rem", marginTop: "0.5rem" }}
          onClick={() => {
            logout();
            navigate("/");
          }}
        >
          {AntonText("Kijelentkezés", 700)}
        </Button>
      );
    } else {
      return (
        <Button
          variant="filled"
          color="green"
          size="sm"
          onClick={() => {
            navigate("/login");
          }}
          style={{ width: 300, marginBottom: "1rem" }}
        >
          {AntonText("Bejelentkezés")}
        </Button>
      );
    }
  };

  const NavLinkButton = ({ to, label }: { to: string; label: string }) => (
    <NavLink
      label={label}
      className={styles.navlinkbutton}
      onClick={() => {
        navigate(to);
        toggle();
      }}
    />
  );

  const ColoredAntonNavLink = ({
    children,
    label,
  }: {
    children: any;
    label: string;
  }) => {
    return (
      <NavLink label={AntonText(label, 700)} className={styles.navbutton}>
        {children}
      </NavLink>
    );
  };

  const getCurrentWeekNumber = () => {
    const currentDate = new Date();
    const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
    const daysToNextMonday =
      januaryFirst.getDay() === 1 ? 0 : (7 - januaryFirst.getDay()) % 7;
    const nextMonday = new Date(
      currentDate.getFullYear(),
      0,
      januaryFirst.getDate() + daysToNextMonday,
    );

    return currentDate < nextMonday
      ? 52
      : currentDate > nextMonday
        ? Math.ceil(
            (currentDate.getTime() - nextMonday.getTime()) /
              (24 * 3600 * 1000) /
              7,
          )
        : 1;
  };

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 350,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header
        style={{ backgroundColor: "var(--mantine-color-myblue-5)" }}
      >
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Image
            src={"/logo.png"}
            h={50}
            w={50}
            radius={"xl"}
            ml={"1rem"}
            onClick={() => {
              navigate("/");
            }}
          />
          <Text
            size="xl"
            className={styles.antontext}
            style={{
              marginLeft: "auto",
            }}
            visibleFrom="sm"
          >
            {"Egyszusz VSE"}
          </Text>
          {isLoggedIn() && (
            <Text
              size="md"
              fw={700}
              ml={"auto"}
              c={"var(--mantine-color-myyellow-3)"}
            >
              {AntonText(
                whoAmI
                  ? whoAmI.firstname && whoAmI.lastname
                    ? `${whoAmI.lastname} ${whoAmI.firstname}`
                    : whoAmI.username
                  : "",
              )}
            </Text>
          )}
        </Group>
      </AppShell.Header>

      <AppShell.Footer
        h={60}
        style={{ backgroundColor: "var(--mantine-color-myblue-5)" }}
      >
        <Image src={"powered_by_strava_1.svg"} className={styles.stravaLogo} />
      </AppShell.Footer>

      <AppShell.Navbar
        p="md"
        style={{ backgroundColor: "var(--mantine-color-myblue-5)" }}
      >
        <ColoredAntonNavLink label="Sportunkról">
          <NavLinkButton to="/about/history" label="Története" />
          <NavLinkButton to="/about/rules" label="Szabályok" />
          <NavLinkButton to="/about/hungarian-teams" label="Csapatok" />
        </ColoredAntonNavLink>

        {isLoggedIn() && (
          <ColoredAntonNavLink label="Statisztika">
            <NavLinkButton
              to="/trainings?pageSize=10&pageNumber=1"
              label="Edzések"
            />
            {(isAdmin() || isClubManager()) && (
              <NavLinkButton to="/players" label="Játékosok" />
            )}
            <NavLinkButton to="/stats/goal" label="Gól" />
            <NavLinkButton
              to="/stats/winning-percentage"
              label="Győzelmi arány"
            />
          </ColoredAntonNavLink>
        )}

        {isLoggedIn() && (
          <ColoredAntonNavLink label="Kihívás">
            <NavLinkButton to="/challenge/periods" label="Összesítő" />
            <NavLinkButton
              to={`/challenge/weekly?week=${getCurrentWeekNumber()}`}
              label="Heti eredmények"
            />
          </ColoredAntonNavLink>
        )}

        {isAdmin() && (
          <ColoredAntonNavLink label="Beállítások">
            <NavLinkButton to="/users" label="Felhasználók" />
          </ColoredAntonNavLink>
        )}

        {(isAdmin() || isClubManager()) && (
          <ColoredAntonNavLink label="Új">
            <NavLinkButton to="/trainings/new" label="Edzés" />
            <NavLinkButton to="/players/new" label="Játékos" />
            <NavLinkButton to="/events/new" label="Esemény" />
          </ColoredAntonNavLink>
        )}

        {isLoggedIn() && (
          <ColoredAntonNavLink label="Egyéb">
            <NavLinkButton to="/events" label="Események" />
            <NavLinkButton to="/team-picker" label="Csapatválasztó" />
          </ColoredAntonNavLink>
        )}

        <Flex mt="auto" direction={"column"} style={{ padding: "1rem" }}>
          {isLoggedIn() && <NotificationRegistrationButton />}
          <AuthButton />
        </Flex>
      </AppShell.Navbar>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};
